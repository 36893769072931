@import "../variables";
@import "../template/mixin_templates";
@import "../../foundation/functions";

/* Général */
body {
    background-color: $body_background_color;
    color: $body_text_color;
}

h2, .h2 {
    color: $titre_color;

    &.titre-article {
        color: $couleur_une;
    }
}

a {
    color: $texte_lien;
    &:hover {
        color: $texte_lien_hover;
    }
}

#wrapper {
    background-color: $content_background_color;
}

/* site en deux colonnes */
.sidebar {
    border: 1px solid $colonne_gauche_border;
}

/* Header */

header {
    background-color: $header_background;
}

.slogan {
    color: $slogan_color_texte;
}

/* Menu */

nav#nav-principal > ul > li:hover > a > span {
    text-decoration: $menu_principal_hover_underline;
}

nav#nav-principal > ul > li > a > span {
    border-right: $menu_principal_li_border_right;
}


#nav-principal{
    background-color: $nav_background_color;
    > ul::after{
        background-color: $li_background_color;
    }
    .navigation_link_p{
        color: $nav_texte_color;
    }
    .navigation_button_p{
        background-color: $li_background_color;
        &.actif, &:hover, &.sousmenu_ouvert{
            background-color: $li_background_color_hover;
            > .navigation_link_p{
                color: $nav_texte_color_hover;
            }
        }
    }
    .sous_navigation_link_p{
        color: $nav_texte_color;
    }
    .sous_navigation_button_p{
        background-color: $sous_menu_li_background_color;
        &.actif, &:hover, &.sousmenu_ouvert{
            background-color: $sous_menu_li_background_color_hover;
            > .sous_navigation_link_p{
                color: $nav_texte_color_hover;
            }
        }
    }
}
#nav-secondary{
    .navigation_link_p{
        color: $secondary_texte_color_hover;
    }
    .navigation_button_p{
        background-color: $secondary_li_background_color;
        &.actif, &:hover, &.sousmenu_ouvert{
            background-color: $secondary_li_background_color_hover;
            > .navigation_link_p{
                color: $secondary_texte_color;
            }
        }
    }
    .sous_navigation_link_p{
        color: $nav_texte_color;
    }
    .sous_navigation_button_p{
        background-color: $sous_menu_li_background_color;
        &.actif, &:hover, &.sousmenu_ouvert{
            background-color: $sous_menu_li_background_color;
            > .sous_navigation_link_p{
                color: $nav_texte_color;
            }
        }
    }
}
#nav-principal .template-panier-2{
    background: $li_background_color_hover;
    #header-panier{
        color:$nav_texte_color_hover;
    }
}

.wrapper-content .menu {
    background-color: $nav_second_background_color;

    .menu-title {
        background-color: $nav_second_background_color_bouton_principal;
        color: $nav_second_color_bouton_principal;
    }
}
nav#nav-principal .navigation_button_p.menu_burger{
    background-color: $nav_second_background_color_bouton_principal;
    &.navigation_link_p{
        color: $nav_second_color_bouton_principal;
    }
}


/* Produits */

.produits {
    .mask {
        a {
            background: $mask_background;
            color: $mask_lien_color;
        }
    }
    a.zoom {
        background: $mask_background;
        color: $mask_lien_color;
    }
    .remise {
        background: $remise_background_color;
        color: $remise_texte_color;
    }
}

.produit_etiquette, .produit_etiquette2, .produit_etiquette3 {
    background: $etiquette_produit_background;
    h3, .h3 {
        color: $etiquette_produit_texte;
    }
}

.produit_etiquette::after, .produit_etiquette2::after, .produit_etiquette3::after {
    border-top-color: $etiquette_produit_background;
}

#fiche-produit .remise-produit {
    background-color: $etiquette_produit_background;
    color: $etiquette_produit_texte;
}

.bloc-avis-clients {

    .moyenne_etoiles {
        color: $avis_etoiles_color;
    }

    .noteComment {
        color: $avis_etoiles_color;
    }

    .message-wrap .media-body .time, .msg-username {
        color: $avis_username_color;
    }
}

/* Galerie photo */

.galerie {
    background: $galerie_mask_background;
    .mask {
        background: $galerie_mask_background;
    }
    .addcommentaires {
        color: $galerie_texte_color;
    }
    .commentP p {
        color: $galerie_texte_color;
    }
    .commentP::before{
        background: $galerie_mask_background;
    }
}

/* Blog / Boutons */

.pagination {
    > li > a {
        background-color: $btn_texte_color;
        color: $btn_background_color;
    }
    li.active a {
        background-color: $btn_background_color;
        border-color: $btn_background_color;
        color: $btn_texte_color;
    }
}

/* Footer */

footer {
    background-color: $footer_background_color;
    color: $footer_texte_color;
    a, p {
        color: $footer_texte_color;
    }
}

/* ----------- Ajout CSS série 819 -------------- */


/*Général*/

h2, .wrap-details > h4, .h2, .wrap-details > .h4, .title_section {
    color: $wraptitles_and_subtitles_color;

    &::after {
        background-color: $wraptitles_and_subtitles_color;
    }
}

.button {
    color: $global_bouton_color;
    border-color: $global_bouton_border_color;
    background: $global_bouton_background;

    &:hover {
        color: $global_bouton_background;
        border-color: $global_bouton_background;
        background-color: $global_bouton_color;
    }
}


/*Panier*/


body .template-panier #panier #paniercontent {
    background-color: $panier_content_background_color;
}

body .template-panier.template-panier-3,
body .template-panier.template-panier-2 {
    background:$panier_background_color;
    .connexion{
        @at-root #header-panier, & p, & p a{
            color: $panier_title_color;
        }
    }
    .count-live {
        color: $panier_count_live_color;
        background-color: $panier_count_live_background;
    }
}

.template-panier-4 {
    .connexion, #panier {
        color: $nav_panier_bullet_color;
        background: $nav_panier_bullet_background;

        .totalpanier {
            color: $nav_panier_bullet_background;
        }
    }
    .connexion a {
        color: $nav_panier_bullet_color;
    }
}

/*Slider*/

.wrapper.accueil_diaporama_template, .accueil_diaporama_template_4 {
    //background-color: $background_bandeau_menu_slider_home !important;
}

.bx-controls a.bx-prev,
.bx-controls a.bx-next {

    &::before {
        background: $slider_nav_bouton_background;
        color: $slider_nav_bouton_color;
    }

    &:hover::before {
        color: $slider_nav_bouton_color_hover;
        border-color: $slider_nav_bouton_color_hover;
    }
}

.bx-controls a.bx-next:hover, .bx-controls a.bx-prev:hover {

    &::after {
        color: $nav_second_background_color;
    }
}

.wrapper.accueil_diaporama_template.accueil_diaporama_template_2 .slide .content-slide-text {

    p, a {
        color: #fff;
        background: rgba(0,0,0,0.5);
    }
}

.wrapper.accueil_diaporama_template.accueil_diaporama_template_3 .slide .content-slide-text {

    p, a {
        color: #fff;
        background: rgba(0,0,0,0.5);
    }
}

.bx-wrapper .bx-pager.bx-default-pager a.active, .bx-wrapper .bx-pager.bx-default-pager a:focus {
    background: $bouton_slider_color;
}

/*Menu principal, secondaire et colonne gauche*/

#nav-secondary ul li a span:after {
    background-color: rgba(0,0,0,0.1);
}

#wrapper .sidebar {
    border-color: $colonne_gauche_border;
}

nav.nav-static-breadcrumb ul li, nav.nav-static-breadcrumb ul li  {

    &::before {
        color: $fil_ariane_color_hover;
    }

    a {
        color: $fil_ariane_color;

        &:hover {
            color: $fil_ariane_color_hover;
        }
    }
}

#nav-static-principal .sous_navigation_button_p{
    background: $sidebar_li_background;
    .sous_navigation_link_p{
        border-color: $colonne_gauche_border;
        color: $sidebar_menu_color;
    }
    &:hover, &.actif {
        background-color: $sidebar_li_background_hover;
        .sous_navigation_link_p{
            color: $sidebar_menu_color_hover;
        }
    }
}

#nav-static-principal .navigation_button_p {
    background: $sidebar_li_background;
    > .navigation_link_p, > .dropDown{
        border-color: $colonne_gauche_border;
        color: $sidebar_menu_color;
    }
    &.actif {
        background-color: $li-isactif_background;
        > .navigation_link_p, > .dropDown{
            color: $sidebar_menu_color_hover;
        }
    }
}

#nav-secondary {

    #header-panier {
        color: $nav_panier_color;
    }

    .count-live {
        color: $nav_panier_bullet_color;
        background: $nav_panier_bullet_background;
    }

    #block-search-menu button {
        background: $nav_second_background_color_bouton_principal;
        color: $nav_second_color_bouton_principal;

        &:hover {
            background: $nav_second_background_color_bouton_principal_hover;
        }
    }

}

/*Boutique accueil et template*/

.produits-accueil.template_boutique_accueil .produits,
.template_boutique .produits {

    .produit_etiquette {
        background: $etiquette_produit_background;
    }

    .oldprix {
        color: $oldprix_color;
    }

    .prixprod {
        color: $prixprod_color;
    }

    .addbasket .inside-addbasket {
        color: $global_bouton_color;

        &::before {
            background: $addbasket_bouton_background;
        }
    }
}

.template_boutique.template_boutique_3 .produits,
.template_boutique.template_boutique_4 .produits {
    background-color: $boutique_background;
}

#wrapper .template_boutique .produits .productContentWrap .desc {
    color: $texte_courant_color;
}

/*Fiche produit*/

.template_fiche_produit .addbasket {
    color: $global_bouton_color;

    &::before {
        background: $addbasket_bouton_background;
    }
}

.note-produit, .note-produit a {
    color: $wraptitles_and_subtitles_color;
}

.template_fiche_produit #fiche-produit {
    a.addbasket {
        color: $global_bouton_color;
    }

    .previews > span {

        &::before {
            background: $slider_nav_bouton_background;
            color: $slider_nav_bouton_color;
        }

        &:hover::before {
            color: $slider_nav_bouton_color_hover;
            border-color: $slider_nav_bouton_color_hover;
        }
    }

    .previews ul li:hover {
        border-color: $slider_nav_bouton_color;
    }

    .wrap-description {

        .oldprix {
            color: $oldprix_color;
        }

        .prixprod {
            color: $prixprod_color;
        }

        .radio_unique {
            background-color: transparent;
            color: $texte_courant_color;
            border-color: transparentize($texte_courant_color, 0.4);

            &:hover, &:focus, &:active, &.radio-checked {
                color: $global_bouton_border_color;
                border-color: $global_bouton_border_color;
            }
        }

        .btn-details {
            color: $global_bouton_border_color;
        }

        a.addbasket::before {
            background: $global_bouton_background;
        }

        .description {
            color: $texte_courant_color;
        }
    }

    .wrap-details, .details {
        color: $texte_courant_color;
    }

    #addcomment, .msg-wrap {
        background-color: $avis_background;
        color: $avis_text_color;
    }
}

#addcomment > div {
    color: $avis_text_color;
}

#wrapper .template_fiche_produit_3 #fiche-produit .side-tabs a {
    background-color: $boutons_description_template_3_background;
    color: $boutons_description_template_3_color;

    &:hover, &.active {
        background-color: $boutons_description_template_3_background_hover;
        color: $boutons_description_template_3_color_hover;
    }
}

/* Livre d'Or */

body .template_livreor_1 .message_lo,
body .template_livreor_2 .list-messages .message_lo .IDLO,
body .template_livreor_2 .list-messages .message_lo .contenuMsgLO,
body .template_livreor_2 .list-messages .message_lo .contenuMsgLO .blcFleche::before,
body .template_livreor_2 .list-messages .message_lo:hover .IDLO,
body .template_livreor_2 .list-messages .message_lo:hover .contenuMsgLO,
body .template_livreor_2 .list-messages .message_lo:hover .contenuMsgLO .blcFleche::before{
    border-color: $message_lo_color;
}
body .template_livreor_2 .list-messages .message_lo .contenuMsgLO .blcFleche::before,
body .template_livreor_2 .list-messages .message_lo:nth-child(2n+1) .contenuMsgLO .blcFleche::before,
body .template_livreor_2 .list-messages .message_lo:hover .contenuMsgLO .blcFleche::before,
body .template_livreor_2 .list-messages .message_lo:nth-child(2n+1):hover .contenuMsgLO .blcFleche::before{
    background-color:$message_lo_color;
}
.template_livreor h4, .template_livreor .h4, .template_livreor .note,
.template_livreor_3 .list-messages .message_lo .contenuMsgLO .message::before,
.template_livreor_3 .list-messages .message_lo .contenuMsgLO .message::after{
    color:$message_lo_color;
}


/* Afficher les bonnes couleurs sur mobile pour le menu */

@media screen and (max-width: $medium-screen){
    nav#nav-principal .navigation_button_p:not(.menu_burger), nav#nav-principal .sous_navigation_button_p {
        background-color: $sous_menu_li_background_color;

        &.actif, &:hover {
            background-color: $sous_menu_li_background_color;
        }
    }

    nav#nav-principal .template-panier-2 #header-panier {
        color: $nav_panier_bullet_color;
        background-color: $nav_panier_bullet_background;
    }

    .template-panier:not(.template-panier-1) .count-live {
        color: $nav_panier_bullet_color;
        background-color: $nav_panier_bullet_background;
        box-shadow: -1px 1px 3px 0 rgba(0,0,0,0.5);
    }
}

.button.button-secondary {
    background: orange;

    &:hover {
        background: #444;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.step-done {
    background: $nav_second_background_color;
    border-color: $nav_second_background_color;

    a {
        color: #fff;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.active {
    border-color: $nav_second_background_color;
    color: $nav_second_background_color;

    a, span {
        color: $nav_second_background_color;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 {
    color: #000;
}